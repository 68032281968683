<template>
  <div class="layout-root">
    <slot />
  </div>
</template>

<script lang="ts">
import type { SlotsType } from 'vue'

export default {
  name: 'DefaultLayout',
  slots: Object as SlotsType<{
    default: {}
  }>
}
</script>

<style lang="scss" scoped>
.layout-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
